// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "chosen-js";
require("jquery");
require("admin-lte");
//= require data-confirm-modal;
//= require chosen-jquery
import toastr from "toastr";
window.toastr = toastr;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

$(function ($) {
  $(document).on("click", ".clickable-row", function () {
    location.href = $(this).data("href");
  });
});

//数値か判定するメソッド
function isNumber(numVal) {
  // チェック条件パターン
  var pattern = /^([1-9]\d*|0)$/;
  // 数値チェック
  return pattern.test(numVal);
}

document.addEventListener("turbolinks:load", function () {
  $(function () {
    $("form").submit(function () {
      $(this).find(":submit").prop("disabled", "true");
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function () {
    $("#exist_p_id").chosen({
      width: "18rem",
      search_contains: true,
      no_results_text: "存在しません",
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $("#name, #kana, #student, #managed_lesson, #opened_on, #closed_on").on(
      "input",
      function () {
        var name = $("#name").val(); //input に入力された文字を取得
        var kana = $("#kana").val(); //input に入力された文字を取得
        var student = $("#student").val();
        var managed_lesson = $("#managed_lesson").val();
        var opened_on = $("#opened_on").val();

        //　閉校済みでないとフォームが表示されないので、closed_onというIdがそもそもあるか場合分け
        if ($("#closed_on").length) {
          var closed_on = $("#closed_on").val();
          if (name && kana && opened_on && closed_on) {
            $("#school-btn").prop("disabled", false);
          } else {
            $("#school-btn").prop("disabled", true);
          }
        } else {
          if (
            name &&
            kana &&
            isNumber(student) &&
            isNumber(managed_lesson) &&
            opened_on
          ) {
            //もし文字が入っていれば
            $("#school-btn").prop("disabled", false); //disabled を無効にする＝ボタンが押せる
          } else {
            $("#school-btn").prop("disabled", true); //disabled を有効にする＝ボタンが押せない
          }
        }
      }
    );
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $(
      "#last, #last_kana, #first, #first_kana, #email, #password, #confirmation_password, #born_on, #entered_on, #retired_on, #role_id, #authority, #hourly_pay, #commuting_costs"
    ).on("input", function () {
      var last = $("#last").val(); //input に入力された文字を取得
      var last_kana = $("#last_kana").val(); //input に入力された文字を取得
      var first = $("#first").val();
      var first_kana = $("#first_kana").val();
      var born_on = $("#born_on").val();

      //権限または、入社か編集によっては表示されないフォームがあるため、フォームの有無で場合分け
      if ($("#email").length) {
        var email = $("#email").val();
      } else {
        email = "true";
      }

      if ($("#password").length) {
        var password = $("#password").val();
      } else {
        password = "true";
      }

      if ($("#confirmation_password").length) {
        var confirmation_password = $("#confirmation_password").val();
      } else {
        confirmation_password = "true";
      }

      if ($("#entered_on").length) {
        var entered_on = $("#entered_on").val();
      } else {
        entered_on = "true";
      }

      if ($("#retired_on").length) {
        var retired_on = $("#retired_on").val();
      } else {
        retired_on = "true";
      }

      if ($("#role_id").length) {
        var role_id = $("#role_id").val();
      } else {
        role_id = "true";
      }

      if ($("#authority").length) {
        var authority = $("#authority").val();
      } else {
        authority = "true";
      }

      if ($("#hourly_pay").length) {
        var hourly_pay = $("#hourly_pay").val();
      } else {
        hourly_pay = "true";
      }

      if ($("#commuting_costs").length) {
        var commuting_costs = $("#commuting_costs").val();
      } else {
        commuting_costs = "true";
      }


      if (
        last &&
        last_kana &&
        first &&
        first_kana &&
        email &&
        password &&
        confirmation_password &&
        born_on &&
        entered_on &&
        retired_on &&
        role_id !== "0" &&
        authority !== "0" &&
        hourly_pay &&
        commuting_costs
      ) {
        //もし文字が入っていれば
        $("#staff-btn").prop("disabled", false); //disabled を無効にする＝ボタンが押せる
      } else {
        $("#staff-btn").prop("disabled", true); //disabled を有効にする＝ボタンが押せない
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $("#new_password, #confirmation_password").on("input", function () {
      var new_password = $("#new_password").val(); //input に入力された文字を取得
      var confirmation_password = $("#confirmation_password").val(); //input に入力された文字を取得

      if (new_password && confirmation_password) {
        //もし文字が入っていれば

        $("#password-edit-btn").prop("disabled", false); //disabled を無効にする＝ボタンが押せる
      } else {
        $("#password-edit-btn").prop("disabled", true); //disabled を有効にする＝ボタンが押せない
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $('[name="class[]"], [name="commuting"], #another_time, #note').on(
      "input",
      function () {
        const checkbox = $('[name="class[]"]');
        const commuting_form = $('[name="commuting"]').val();
        const another_time_form = $("#another_time").val();
        const note = $("#note").val();
        if (
          (!checkbox[0].checked &&
            !checkbox[1].checked &&
            !checkbox[2].checked &&
            !checkbox[3].checked &&
            !checkbox[4].checked) ||
          !commuting_form.trim() ||
          isNaN(commuting_form) ||
          (another_time_form != 0 && !note)
        ) {
          $("#attendance-btn, #attendance-create-btn").prop("disabled", true);
        } else {
          $("#attendance-btn, #attendance-create-btn").prop("disabled", false);
        }
      }
    );
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $(".prev-month a, .next-month a")
      .css("pointer-events", "none")
      .attr("tabindex", -1);
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $("#payroll_edit_btn").on("click", function () {
      $("#payroll_save_btn").css("display", "");
      $("#payroll_edit_btn").css("display", "none");
      $("#__lists").find("input,textarea,select").prop("disabled", false);
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $("#is_retired").on("click", function () {
      var url = location.href;
      if ($(this).prop("checked") === false) {
        if (url.includes("status")) {
          location.href = "/admin/staffs";
        } else {
          location.href = url.slice(0, -16); // &is_retired=trueを除いたurl
        }
      } else if (url.includes("?")) {
        location.href = url + "&is_retired=true";
      } else {
        location.href = url + "?is_retired=true";
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $("#is_left").on("click", function () {
      var url = location.href;
      var new_url = url.replace(/.is_left=true/, "").replace(/&/, "?");
      if ($(this).prop("checked") === false) {
        location.href = new_url;
      } else if (url.includes("?")) {
        location.href = url + "&is_left=true";
      } else {
        location.href = url + "?is_left=true";
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $("#password, #new_email").on("input", function () {
      var newEmail = $("#new_email").val(); //input に入力された文字を取得

      if ($("#password").length) {
        var password = $("#password").val();
      } else {
        password = "true";
      }

      if (password && newEmail) {
        //もし文字が入っていれば

        $("#email-edit-btn").prop("disabled", false); //disabled を無効にする＝ボタンが押せる
      } else {
        $("#email-edit-btn").prop("disabled", true); //disabled を有効にする＝ボタンが押せない
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    const school_number = document.getElementById("school_number").value;
    for (let i = 0; i <= school_number; i++) {
      $(`#managed_lesson_${i}`).on("keypress", function (event) {
        return leaveOnlyNumber(event);
      });
      $(`#student_${i}`).on("keypress", function (event) {
        return leaveOnlyNumber(event);
      });
      $(`#managed_lesson_${i}, #student_${i}`).on("input", function () {
        var managed_lesson = document.getElementById(
          `managed_lesson_${i}`
        ).value;
        var student = document.getElementById(`student_${i}`).value;
        var incentive = document.getElementById(`incentive_${i}`).value;
        var written_incentive = managed_lesson * 1000 + student * 500;
        var dif;
        if (written_incentive - incentive > 0) {
          dif = "(+" + String(written_incentive - incentive) + ")";
        } else if (written_incentive - incentive < 0) {
          dif = "(" + String(written_incentive - incentive) + ")";
        } else {
          dif = "";
        }
        if (
          managed_lesson &&
          student &&
          isNumber(managed_lesson) &&
          isNumber(student)
        ) {
          //もし文字が入っていれば
          $("#payroll_school_submit").prop("disabled", false); //disabled を無効にする＝ボタンが押せる
        } else {
          $("#payroll_school_submit").prop("disabled", true); //disabled を有効にする＝ボタンが押せない
        }
        if (incentive != 0) {
          $(`#incentive_form_${i}`).text(`¥${written_incentive}`);
          $(`#dif_${i}`).text(`${dif}`);
        }
      });
    }
  });
});

function leaveOnlyNumber(e) {
  // 数字以外の不要な文字を削除
  var st = String.fromCharCode(e.which);
  if ("0123456789".indexOf(st, 0) < 0) {
    return false;
  }
  return true;
}

document.addEventListener("turbolinks:load", function () {
  $(function () {
    $("#work_schedule_school_form").change(function () {
      var url = location.href;
      var id = document.getElementById("school_id").value; //セレクトで選択された教室
      let sub = url.split("/");
      $("#submit_form").submit();
      location.href = `/admin/work_schedules/${sub[5]}/${sub[6]}/schools/${id}`;
    });
    $(".estimate_payrolls_school_form").change(function () {
      const currentUrl = location.href;
      let split_url = currentUrl.split("/");
      var url = `/admin/work_schedules/estimate_payrolls/${split_url[6]}/${split_url[7]}`;
      var new_url = url.replace(/\?.*$/, "");
      var id = document.getElementById("school_id").value; //セレクトで選択された教室
      if (id == 0) {
        location.href = `${new_url}/schools`;
      } else {
        location.href = new_url + `?schools=${id}`;
      }
    });
    var id = document.getElementById("school_id").value;
    document
      .querySelector("body")
      .setAttribute("data-theme", `selected_school_${((id - 1) % 5) + 1}`);
    let base_date = formatDate(new Date());
    var target_y = $(`#${base_date}`).offset().top - 128;
    $(".work_schedules").stop().animate({ scrollTop: target_y }, 500);
  });
});

function formatDate(date) {
  var y = date.getFullYear();
  var m = ("00" + (date.getMonth() + 1)).slice(-2);
  var d = ("00" + date.getDate()).slice(-2);
  return y + "-" + m + "-" + d;
}

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $("#work-template-school-form").change(function () {
      var url = location.href;
      var id = document.getElementById("work-template-school-form").value; //セレクトで選択された教室
      location.href = `/admin/work_schedules/templates/schools/${id}`;
    });
    var id = document.getElementById("work-template-school-form").value;
    document
      .querySelector("body")
      .setAttribute("data-theme", `selected_school_${((id - 1) % 5) + 1}`);
  });
});
document.addEventListener("turbolinks:load", function () {
  $(function () {
    $("#work_schedule_staff_form").change(function () {
      var url = location.href;
      var id = document.getElementById("staff_id").value; //セレクトで選択されたスタッフ
      let sub = url.split("/");
      $("#submit_form").submit();
      location.href = `/admin/work_schedules/${sub[5]}/${sub[6]}/staffs/${id}`;
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $("#payroll-school-form").change(function () {
      const currentUrl = location.pathname;
      var id = document.getElementById("payroll-school-form").value; //セレクトで選択された教室
      if (id == 0) {
        location.href = currentUrl;
      } else {
        location.href = currentUrl + `?school=${id}`;
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    $("#student-school-form").change(function () {
      var param = location.search;
      location.href = `/students/school/${id}/`;

      var id = document.getElementById("student-school-form").value; //セレクトで選択された教室
      if (id == 0) {
        location.href =  `/students` + param;
      } else if (param == "") {
        location.href = `/students/school/${id}`;
      } else{
        location.href = `/students/school/${id}` + param;
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function ($) {
    if ($("#another_time option:selected").val() !== "0") {
      document.getElementById("note_editor").style.display = "";
    }
    $("#another_time").change(function () {
      let id = document.getElementById("another_time").value;
      if (id == "0") {
        document.getElementById("note_editor").style.display = "none";
      } else {
        document.getElementById("note_editor").style.display = "";
      }
    });

    if ($("#deduction_time option:selected").val() !== "0.0") {
      document.getElementById("late-early-editor").style.display = "flex";
      $("#collapse").text("閉じる ➖");
      $("#collapse").parent().addClass("py-1 my-3 bg-light");
    }

    $("#collapse").click(function () {
      let status = document.getElementById("late-early-editor");
      if (status.style.display == "flex") {
        status.style.display = "none";
        $("#collapse").text("遅刻・早退 ➕");
        $("#collapse").parent().removeClass();
      } else {
        status.style.display = "flex";
        $("#collapse").text("閉じる ➖");
        $("#collapse").parent().addClass("py-1 my-3 bg-light");
      }
    });

    if ($("#total_training_lesson option:selected").val() !== "0") {
      document.getElementById("total_training_lesson_editor").style.display =
        "flex";
      $("#total_training_lesson_btn").text("閉じる ➖");
      $("#total_training_lesson_btn").parent().addClass("py-1 my-3 bg-light");
    }
    $("#total_training_lesson_btn").click(function () {
      let status = document.getElementById("total_training_lesson_editor");
      if (status.style.display === "flex") {
        status.style.display = "none";
        $("#total_training_lesson_btn").text("研修コマの設定 ➕");
        $("#total_training_lesson_btn").parent().removeClass();
      } else {
        status.style.display = "flex";
        $("#total_training_lesson_btn").text("閉じる ➖");
        $("#total_training_lesson_btn").parent().addClass("py-1 my-3 bg-light");
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function () {
    $("#sort_student").change(function () {
      var url = location.href;
      var left_flag = "";
      var query_param = "";
      url.match(/is_left=true/) ? (left_flag = true) : (left_flag = false);
      var selected_value = document.getElementById("sort_student").value;
      var school_id = document.getElementById("student-school-form").value; 
      switch (selected_value) {
        case "default":
          left_flag ? (query_param = "?is_left=true") : (query_param = "");
          break;
        case "sort_by_name":
          left_flag
            ? (query_param = "?is_left=true&sort_by_name=true")
            : (query_param = "?sort_by_name=true");
          break;
        case "sort_by_old":
          left_flag
            ? (query_param = "?is_left=true&sort_by_old=true")
            : (query_param = "?sort_by_old=true");
          break;
        case "sort_by_young":
          left_flag
            ? (query_param = "?is_left=true&sort_by_young=true")
            : (query_param = "?sort_by_young=true");
          break;
        case "sort_by_lesson":
          left_flag
            ? (query_param = "?is_left=true&sort_by_lesson=true")
            : (query_param = "?sort_by_lesson=true");
          break;
        case "sort_by_course":
          left_flag
            ? (query_param = "?is_left=true&sort_by_course=true")
            : (query_param = "?sort_by_course=true");
          break;
        case "sort_by_status":
          left_flag
            ? (query_param = "?is_left=true&sort_by_status=true")
            : (query_param = "?sort_by_status=true");
          break;
        case "sort_by_school":
          left_flag
            ? (query_param = "?is_left=true&sort_by_school=true")
            : (query_param = "?sort_by_school=true");
          break;
      }
      // 教室絞込の有無で遷移先urlを分岐
      window.location.href = (school_id !== "0") ? (`/students/school/${school_id}${query_param}`) : (`/students${query_param}`);
    });
    var url = location.href;
    var query_param = url.match(/sort_by_.+?=/);
    var sort_by = query_param[0].slice(0, -1);
    $(`#sort_student option[value='${sort_by}']`).prop("selected", true);
  });
  $(function () {
    $("#sort_parent").change(function () {
      var url = location.href;
      var left_flag = "";
      var query_param = "";
      url.match(/is_left=true/) ? (left_flag = true) : (left_flag = false);
      var selected_value = document.getElementById("sort_parent").value;
      switch (selected_value) {
        case "default":
          left_flag ? (query_param = "?is_left=true") : (query_param = "");
          break;
        case "sort_by_name":
          left_flag
            ? (query_param = "?is_left=true&sort_by_name=true")
            : (query_param = "?sort_by_name=true");
          break;
        case "sort_by_payment_type":
          left_flag
            ? (query_param = "?is_left=true&sort_by_payment_type=true")
            : (query_param = "?sort_by_payment_type=true");
          break;
      }
      window.location.href = `/parents${query_param}`;
    });
    var url = location.href;
    var query_param = url.match(/sort_by_.+?=/);
    var sort_by = query_param[0].slice(0, -1);
    $(`#sort_parent option[value='${sort_by}']`).prop("selected", true);
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function () {
    $("#parent_select").change(function () {
      var id = document.getElementById("parent_select").value; //1:新規作成、2:既存選択
      if (id === "new") {
        $("#new_parent").css("display", "");
        $("#exist_parent").css("display", "none");
        $("#payment_info").css("display", "");
      } else if (id === "exist") {
        $("#new_parent").css("display", "none");
        $("#exist_parent").css("display", "");
        $("#payment_info").css("display", "none");
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function () {
    $("#sort_bill").change(function () {
      var url = location.href.replace(/\?.+/, "");
      var query_param = "";
      var selected_value = document.getElementById("sort_bill").value;
      switch (selected_value) {
        case "default":
          query_param = "";
          break;
        case "name":
          query_param = "?sort=name";
          break;
        case "parent_id":
          query_param = "?sort=parent_id";
          break;
      }
      window.location.href = `${url}${query_param}`;
    });
  });
});


document.addEventListener("turbolinks:load", function () {
  $(function () {
    $("#number_of_lessons").change(function () {
      var number_of_lessons = document.getElementById("number_of_lessons").value;
      var num_of_first_month = document.getElementById('num_of_first_month');
      if (number_of_lessons === "2") {
        num_of_first_month.options[1].selected = true;
      } else if (number_of_lessons === "4") {
        num_of_first_month.options[3].selected = true;
      }
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function () {
    $("#address_button").on('click', function () {
      var api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=';
      var zip = document.getElementById("zip");
      var zip_error = document.getElementById('zip_error');
      var address = document.getElementById("address");
      var param = zip.value.replace("-", "");
      var url = api + param;

      fetchJsonp(url, {
        timeout: 10000, //タイムアウト時間
      })
      .then((response)=>{
        zip_error.textContent = ''; //HTML側のエラーメッセージ初期化
            return response.json();
      })
      .then((data)=>{
        if(data.status === 400){ //エラー時
          zip_error.textContent = data.message;
        }else if(data.results === null){
          zip_error.textContent = '郵便番号から住所が見つかりませんでした。';
        } else {
          address.value = data.results[0].address1 + data.results[0].address2 + data.results[0].address3;
        }
      })
      .catch((ex)=>{ //例外処理
        console.log(ex);
      });
    });
  });
});

document.addEventListener("turbolinks:load", function () {
  $("#payment_type").change(function () {
    var payment_type = document.getElementById("payment_type").value;
    if (payment_type === "TRANSFER"){
      $("#branch_number_block").css("display", "none");
      $("#account_number_block").css("display", "none");
      $("#account_name_block").css("display", "none");
      $("#withdrawal_month_block").css("display", "none");
    } else if (payment_type === "DEBIT"){
      $("#branch_number_block").css("display", "");
      $("#account_number_block").css("display", "");
      $("#account_name_block").css("display", "");
      $("#withdrawal_month_block").css("display", "");
    }
  });
});

document.addEventListener("turbolinks:load", function () {
  $("#result, #paid_on").change(function () {
    var result = $("#result").val();
    var paid_on = $("#paid_on").val();
    if (result === 'true' && paid_on){
      $("#paid_on_form").css("display", "");
      $("#transfer_edit_save_btn").prop("disabled", false);
    } else if(result === 'true'){
      $("#paid_on_form").css("display", "");
      $("#transfer_edit_save_btn").prop("disabled", true);
    } else {
      $("#paid_on_form").css("display", "none");
      $("#transfer_edit_save_btn").prop("disabled", false);
    }
  });
});

